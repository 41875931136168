import React from "react";
import Link from "gatsby-link";

import {
  Jumbotron,
  Grid,
  Row,
  Col,
  Image,
  Button,
  Container
} from "react-bootstrap";
import AltHeader from "../../components/alt-header.js";
import Header from "../../components/header.js";
import UpNext from "../../components/up-next.js";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar.js";

const ExperiencePage = () => (
  <>
    <Navbar />

    <Row className="show-grid">
      <Col xs={12}>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </Col>
    </Row>

    <Row className="show-grid pb60" style={{ position: "relative" }}>

      <Col
        xs={12}
        sm={{ span: 12 }}
        md={{ span: 7 }}
        className="experience-section"
      >
        <h3>Experience</h3>

        <h5>
          Cappfinity - <em>Current Position</em>
        </h5>

        <p>
          I am currently a Senior Designer at Cappfinity. My main
          responsibilities include working on the improvment of the UI and UX of
          our products, development of the website, supporting the heads of
          design and mentoring the more junior members of the team.
          <br />
          <br />I am currently working on writing the brand guidelines as well
          as revamping the UI on our dashboarding tool.
        </p>

        <h5>
          A.S. Watson - <em>Jan 2015 - Sept 2019</em>
        </h5>

        <p>
          At A.S. Watson I was lucky enough to have the opportunity to work on
          some incredible projects. Over the past 4 years I have worked on the
          launch of Superdrug Mobile, re-designed the Marionnaud website and
          created animations for The Perfume Shop.
          <br />
          <br />
          My day to day responsibilities included supporting 3 Business Units,
          helping to design and code brand landing pages, emails, designing
          in-store POS and creating animations. <br />
          <br />
          Part of my role was also helping to support the more junior members of
          the team, helping to manage workloads, support with code and giving
          feedback on designs.
        </p>

        <h5>
          Spread Co - <em>Feb 2013 - Dec 2014</em>
        </h5>

        <p>
          At Spread Co I was considered a key member of the marketing team. Some
          of my responsibilities were designing all press ads, all online
          advertising materials (including HTML5 banners), creating HTML emails,
          overseeing design on the website and making sure the brand guidelines,
          that I wrote, were adhered to.
          <br />
          <br />I also took on an intern who I went on to take on as a part time
          junior. I was fully responsible for managing his workload and signing
          off his work.
        </p>

        <h5>
          Data Transmission - <em>Aug 2012 - Feb 2013</em>
        </h5>

        <p>
          Responsibilities include designing and building HTML emails for Data
          Trans-mission and all of their clients, sending out Data Transmission
          emails and liais-ing with clients.
        </p>

        <h5>
          Smiial - <em>Sept 2011 - Aug 2012</em>
        </h5>

        <p>
          Smiial is a platform designed for sharing. It uses patented
          technologies to deliver rewards in exchange for sharing through
          Facebook and Twitter.
          <br />
          <br />
          After helping getting Smiial to Beta stage, I was then tasked with
          completely re designing the platform. I worked closely with the sole
          developer of Smiial to completely re work things, from the ground up.
          <br />
          <br /> This gave me a great understanding of how social networks work,
          and what it takes to design an entire platform from scratch.
        </p>

        <h5>
          Mythicus - <em>June 2011 - Sept 2011</em>
        </h5>

        <p>
          Mythicus are a Design and marketing company based in Moorgate. Whilst
          there I worked on various websites and projects. Including the follow
          up to the film ‘Trainspotting’ called ‘Ecstasy’. For this I have
          created DVD covers, posters and trailers.
        </p>
      </Col>
      <Col xs={12} sm={12} md={5} lg={4}>
        <div className="skillset-box">
          <h2>Skillset</h2>
          <h5>Design</h5>
          <p className="skillset-list">
            Figma <br />
            Sketch <br />
            Framer X <br />
            Photoshop <br />
            Illustrator <br />
            InDesign <br />
            After Effects
          </p>
          <h5>Code</h5>
          <p className="skillset-list">
            React <br />
            Gatsby <br />
            HTML <br />
            CSS <br />
            Javascript <br />
            VS Code
          </p>
        </div>
      </Col>
    </Row>
    <Row className="clear-fix">
      <Col>&nbsp;</Col>
    </Row>
  </>
);

export default ExperiencePage;
